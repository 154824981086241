import * as React from "react"

import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { CancelTokenSource } from "axios"
import { IGetMembershipPlansDataResponseApi } from "@interfaces/IGetMembershipPlansDataResponseApi.ts"

import publicApi from "@others/public.ts"
import usePlanDataCtx from "@contexts/hooks/usePlanDataCtx.tsx"

const jsonName = "plan-data-20250219.json"

export const usePlanData = () => {
	const { i18n } = useTranslation()
	const { planData, setPlanData } = usePlanDataCtx()

	const [loading, setLoading] = React.useState<boolean>(true)

	const sourceRef = useRef<CancelTokenSource | null>(null)

	const fetchPlanData = React.useCallback(() => {
		if (sourceRef.current) sourceRef.current.cancel()

		setLoading(true)

		publicApi
			.get<IGetMembershipPlansDataResponseApi>(
				`json/${i18n.language}/${jsonName}`
			)
			.then((response) => {
				setPlanData(response.data)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [i18n.language])

	return {
		planData,
		loading,
		fetchPlanData,
	}
}
