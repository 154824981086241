import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./PersonalDataForm.module.scss"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppMessage } from "@others/AppMessage.ts"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { usePersonalDataForm } from "@hooks/usePersonalDataForm.tsx"

import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import userService from "@services/userService/userService.ts"
import DvPhotoUpload from "@components/dataEntry/DvPhotoUpload/DvPhotoUpload.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import validationRules from "@others/validationRules.ts"
import PhotoUploadFormItem from "@components/layout/PhotoUploadFormItem/PhotoUploadFormItem.tsx"
import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"
import { AppNotification } from "@others/AppNotification.ts"

export interface IPersonalDataFormFieldType {
	name?: string
	lastName?: string
	email?: string
	phone?: string
	profilePhoto?: string
}

interface IProps {
	className?: string
}

const PersonalDataForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { loggedUser, setLoggedUser } = useAuthCtx()
	const { isMobile, isDesktop } = useResponsiveToolCtx()

	const navigate = useNavigate()

	const [form] = Antd.Form.useForm<IPersonalDataFormFieldType>()

	const [saveChangesLoading, setSaveChangesLoading] = React.useState(false)

	const initialValues: IPersonalDataFormFieldType = React.useMemo(
		() => ({
			name: loggedUser?.name,
			lastName: loggedUser?.lastName,
			email: loggedUser?.email,
			phone: loggedUser?.phone,
			profilePhoto: loggedUser?.profilePhoto,
		}),
		[loggedUser]
	)

	const { profilePhoto, onChange } = usePersonalDataForm(form, initialValues)

	const onFinish = (formValues: IPersonalDataFormFieldType) => {
		setSaveChangesLoading(true)

		userService
			.updateUserData({
				name: formValues.name!,
				lastName: formValues.lastName!,
				email: formValues.email!,
				phone: formValues.phone!,
				profilePhoto: profilePhoto,
			})
			.then(({ user }) => {
				setLoggedUser(user)
				AppNotification.success({
					message: t("changes_saved_successfully"),
				})
				navigate(AppRoutes.userSettings)
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setSaveChangesLoading(false)
			})
	}

	return (
		<div
			className={`${styles.personal_data_form} ${props.className || ""}`}
		>
			<main className={styles.main}>
				<div className={styles.header}>
					<AntdIcons.ArrowLeftOutlined
						className={styles.icon}
						onClick={() => navigate(AppRoutes.userSettings)}
					/>

					<h1 className={styles.title}>{t("personal_data")}</h1>
				</div>

				<DvAntdForm<IPersonalDataFormFieldType>
					form={form}
					className={`${styles.user_personal_data_form} ${
						props.className || ""
					}`}
					initialValues={initialValues}
					onFinish={onFinish}
				>
					<DvAntdFormItem<IPersonalDataFormFieldType>
						label={t("name")}
						name="name"
						rules={[
							validationRules.requiredFieldRule(
								t("please_enter_your_name")
							),
						]}
					>
						<DvInput placeholder={t("insert_your_name")} />
					</DvAntdFormItem>

					<DvAntdFormItem<IPersonalDataFormFieldType>
						label={t("lastName")}
						name="lastName"
						rules={[
							validationRules.requiredFieldRule(
								t("please_enter_your_lastName")
							),
						]}
					>
						<DvInput placeholder={t("insert_your_lastName")} />
					</DvAntdFormItem>

					<DvAntdFormItem<IPersonalDataFormFieldType>
						label={t("email")}
						name="email"
						validateDebounce={1500}
						rules={[
							validationRules.requiredFieldRule(
								t("please_enter_your_email")
							),
							validationRules.emailRule(
								t("your_email_is_not_valid")
							),
						]}
					>
						<DvInput placeholder={t("insert_your_email")} />
					</DvAntdFormItem>

					<DvAntdFormItem<IPersonalDataFormFieldType>
						label={t("phone")}
						name="phone"
						rules={[
							validationRules.requiredFieldRule(
								t("please_enter_your_phone")
							),
							validationRules.phoneRule(),
						]}
					>
						<DvInput placeholder={t("insert_your_phone")} />
					</DvAntdFormItem>

					<PhotoUploadFormItem
						className={styles.photo_upload_form_item}
					>
						{/*FIXE: when the user don't have photo the the upload is broken*/}
						<DvPhotoUpload
							initialBase64Image={initialValues.profilePhoto}
							base64Image={profilePhoto}
							onSuccess={({ base64Image }) => {
								onChange("profilePhoto", base64Image)
							}}
							onRemove={() => {
								onChange("profilePhoto", undefined)
							}}
						/>
					</PhotoUploadFormItem>
				</DvAntdForm>

				{isDesktop && (
					<DsButton
						className={styles.footer_button}
						loading={saveChangesLoading}
						onClick={() => form.submit()}
					>
						{t("save_changes")}
					</DsButton>
				)}
			</main>

			{isMobile && (
				<footer className={styles.footer}>
					<DsButton
						className={styles.footer_button}
						loading={saveChangesLoading}
						onClick={() => form.submit()}
					>
						{t("save_changes")}
					</DsButton>
				</footer>
			)}
		</div>
	)
}

export default React.memo(PersonalDataForm)
