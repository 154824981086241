import * as React from "react"
import * as Antd from "antd"
import styles from "./AppText.module.scss"

import { AppConfig } from "@constants/AppConfig.ts"
import { IAppHtmlTags } from "@interfaces/generic/IAppHtmlTags.ts"
import { useTranslation } from "react-i18next"

export interface IAppTextProps {
	className?: string
	tag?: IAppHtmlTags
	textKey?: string
	children?: string
	ellipsis?: boolean
}

const AppText: React.FC<IAppTextProps> = (props) => {
	const textRef = React.useRef<any>(null)

	const { t } = useTranslation()
	const [isOverflowing, setIsOverflowing] = React.useState(false)

	React.useEffect(() => {
		if (!textRef.current || !props.ellipsis) return

		const resizeObserver = new ResizeObserver(() => {
			setIsOverflowing(
				textRef.current!.scrollWidth > textRef.current!.clientWidth
			)
		})

		resizeObserver.observe(textRef.current!)

		return () => resizeObserver.disconnect()
	}, [])

	const Wrapper = props.tag || "p"

	return (
		<Antd.Tooltip
			title={isOverflowing ? props.children : undefined}
			placement={"topRight"}
		>
			<Wrapper
				ref={textRef}
				className={`${styles.app_text} ${props.ellipsis ? styles.ellipsis : ""} ${props.className || ""}`}
			>
				{props.textKey
					? t(props.textKey)
					: props.children || AppConfig.APP_NOT_VALUE}
			</Wrapper>
		</Antd.Tooltip>
	)
}

export default React.memo(AppText)
