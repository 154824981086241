import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./MembershipAddressForm.module.scss"

import { useTranslation } from "react-i18next"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useListOfAllCountries } from "@hooks/useListOfAllCountries.tsx"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"
import DvAntdFormItem from "@components/others/DvAntdFormItem/DvAntdFormItem.tsx"
import DvAntdForm from "@components/others/DvAntdForm/DvAntdForm.tsx"
import validationRules from "@others/validationRules.ts"

export interface IMembershipAddressFormFieldType {
	country?: string
	city?: string
	address_1?: string
	address_2?: string
	zipCode?: string
}

interface IProps {
	className?: string
}

const MembershipAddressForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const addressInputRef = React.useRef<Antd.InputRef>(null)
	const { t } = useTranslation()
	const [form] = Antd.Form.useForm<IMembershipAddressFormFieldType>()
	const [findAddress, setFindAddress] = React.useState<string>()
	const { countries, loading } = useListOfAllCountries()

	const [isFormFilledManually, setIsFormFilledManually] =
		React.useState(false)

	const [currentLocation, setCurrentLocation] =
		React.useState<google.maps.LatLng | null>(null) // Store user's location

	const initialValues: IMembershipAddressFormFieldType = React.useMemo(
		() => ({
			country: undefined,
			city: undefined,
			address_1: undefined,
			address_2: undefined,
			zipCode: undefined,
		}),
		[]
	)

	const { onChange } = useMembershipApplyForm(form, initialValues)

	const onFinishFailed = () => {
		setIsFormFilledManually(true)
	}

	const onFinish = async ({
		country,
		address_1,
		address_2,
		city,
		zipCode,
	}: IMembershipAddressFormFieldType) => {
		await onChange("country", country)
		await onChange("address_1", address_1)
		await onChange("address_2", address_2)
		await onChange("city", city)
		await onChange("zipCode", zipCode)

		navigate(AppRoutes.membershipFormStep1_kind_of_membership, {
			replace: true,
		})
	}

	const extractAddressComponents = (
		addressComponents: google.maps.GeocoderAddressComponent[]
	) => {
		let country = ""
		let city = ""
		let zipCode = ""
		let address_2

		addressComponents.forEach((component) => {
			const types = component.types

			if (types.includes("country")) {
				country = component.short_name
			}

			if (types.includes("locality")) {
				city = component.long_name
			}

			if (types.includes("postal_code")) {
				zipCode = component.long_name
			}

			if (
				types.includes("sublocality") ||
				types.includes("neighborhood")
			) {
				address_2 = component.long_name
			}
		})

		return {
			country,
			city,
			zipCode,
			address_2,
		}
	}

	React.useEffect(() => {
		if (addressInputRef.current && window.google) {
			const autocompleteInstance =
				new window.google.maps.places.Autocomplete(
					addressInputRef.current.input!,
					{
						types: ["geocode"],
					}
				)

			if (currentLocation) {
				autocompleteInstance.setBounds(
					new google.maps.LatLngBounds(currentLocation)
				)
			}

			autocompleteInstance.addListener("place_changed", async () => {
				const place = autocompleteInstance.getPlace()
				const addressComponents = place.address_components
				const address_1 = place.formatted_address

				setFindAddress(address_1)

				if (addressComponents) {
					const extractedAddress =
						extractAddressComponents(addressComponents)

					form.setFieldsValue({
						address_1,
						...extractedAddress,
					})

					await onChange("address_1", address_1)
					await onChange("address_2", extractedAddress.address_2)
					await onChange("city", extractedAddress.city)
					await onChange("zipCode", extractedAddress.zipCode)
					await onChange("country", extractedAddress.country)
				}
			})
		}
	}, [currentLocation, form])

	React.useEffect(() => {
		AppFunctions.loadGoogleMapsAPI(() => {
			AppFunctions.getUserLocation(({ lng, lat }) => {
				setCurrentLocation(new google.maps.LatLng(lat, lng))
			})
		})
	}, [])

	return (
		<>
			<DvAntdForm<IMembershipAddressFormFieldType>
				form={form}
				className={`${styles.membership_address_form} ${props.className || ""}`}
				initialValues={initialValues}
				onFinishFailed={onFinishFailed}
				onFinish={onFinish}
			>
				{/* FIXE: see what wrong with the autocomplete that sometimes work and others not*/}
				<DvAntdFormItem<IMembershipAddressFormFieldType>
					label={t("find_address")}
				>
					<DvInput
						ref={addressInputRef}
						placeholder={t("insert_an_address")}
						suffix={<AntdIcons.SearchOutlined />}
						value={findAddress}
						onChange={(e) => setFindAddress(e.target.value)}
						disabled={isFormFilledManually}
					/>
				</DvAntdFormItem>

				<DsButton
					className={styles.manual_address_button}
					type="text"
					onClick={() =>
						setIsFormFilledManually(!isFormFilledManually)
					}
					icon={
						!isFormFilledManually ? (
							<AntdIcons.EditOutlined />
						) : (
							<AntdIcons.SearchOutlined />
						)
					}
				>
					{!isFormFilledManually
						? t("insert_address_manually")
						: t("use_address_finder")}
				</DsButton>

				<DvAntdFormItem<IMembershipAddressFormFieldType>
					label={t("country-region")}
					name="country"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_country_region")
						),
					]}
				>
					<DvSelect
						options={countries}
						disabled={!isFormFilledManually}
						loading={loading}
						onSelect={(value) => onChange("country", value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipAddressFormFieldType>
					label={t("address_line_#").replace("#", "1")}
					name="address_1"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_address")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_your_address")}
						disabled={!isFormFilledManually}
						onChange={(e) => onChange("address_1", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipAddressFormFieldType>
					label={t("address_line_#").replace("#", "2")}
					name="address_2"
				>
					<DvInput
						placeholder={t("insert_your_address")}
						disabled={!isFormFilledManually}
						onChange={(e) => onChange("address_2", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipAddressFormFieldType>
					label={t("city")}
					name="city"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_city")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_your_city")}
						disabled={!isFormFilledManually}
						onChange={(e) => onChange("city", e.target.value)}
					/>
				</DvAntdFormItem>

				<DvAntdFormItem<IMembershipAddressFormFieldType>
					label={t("postal_zip_code")}
					name="zipCode"
					rules={[
						validationRules.requiredFieldRule(
							t("please_enter_your_postal_zip_code")
						),
					]}
				>
					<DvInput
						placeholder={t("insert_your_postal_zip_code")}
						disabled={!isFormFilledManually}
						onChange={(e) => onChange("zipCode", e.target.value)}
					/>
				</DvAntdFormItem>
			</DvAntdForm>

			<MembershipFormFooter
				onBackClick={() =>
					navigate(AppRoutes.membershipFormStep1_details)
				}
				onNextClick={() => form.submit()}
			/>
		</>
	)
}

export default React.memo(MembershipAddressForm)
