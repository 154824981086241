import * as process from "node:process"

import { AppRoutes } from "@constants/AppRoutes.ts"
import { IViteEnv } from "@interfaces/generic/IViteEnv.ts"
import { AppBookingStatus } from "@constants/AppBookingStatus.ts"
import { BookingCategories } from "@constants/AppCategoryStatus.ts"
import { IGetErrorApiDetail } from "@interfaces/IGetErrorApiDetail.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { ICheckIfIsInTheFuture } from "@hooks/useAppDateTime.d"
import { IPostLoginResponseApi } from "@interfaces/IPostLoginResponseApi.ts"
import { IGetUserLoggedResponseCli } from "@interfaces/IGetUserLoggedResponseCli.ts"
import { IGetMyBookingsResponseCli } from "@interfaces/IGetMyBookingsResponseCli.ts"

import {
	AppMembershipStatus,
	FALLBACK_MEMBERSHIP_STATUS,
} from "@constants/AppMembershipStatus.ts"

import dayjs from "dayjs"
import IndexedDB from "@services/indexedDBService.ts"

export const AppFunctions = {
	isTokenExpiringSoon: (): boolean => {
		const expirationTime = localStorage.getItem(
			AppLocalStorageKeys.TOKEN_EXPIRATION_TIME
		)

		if (!expirationTime) return true

		const expirationTimeMs = parseInt(expirationTime, 10)
		const currentTime = Date.now()
		const timeLeft = expirationTimeMs - currentTime

		return timeLeft < 300000 // Return true if less than 5 minutes (300000 ms) remain
	},
	getMembershipStatusCli: (
		membershipStatusApi: string | undefined
	): IGetUserLoggedResponseCli["membershipStatus"] => {
		switch (membershipStatusApi) {
			case AppMembershipStatus.NO_MEMBERSHIP:
				return AppMembershipStatus.NO_MEMBERSHIP
			case AppMembershipStatus.PENDING_TO_APPROVE:
				return AppMembershipStatus.PENDING_TO_APPROVE
			case AppMembershipStatus.APPROVED:
				return AppMembershipStatus.APPROVED
			case AppMembershipStatus.PAYMENT_IN_PROGRESS:
				return AppMembershipStatus.PAYMENT_IN_PROGRESS
			case AppMembershipStatus.PAID:
				return AppMembershipStatus.PAID
			default:
				console.error(
					`Membership status ${membershipStatusApi} not found.
				 	 The fallback value ${FALLBACK_MEMBERSHIP_STATUS} will be used.`
				)
				return FALLBACK_MEMBERSHIP_STATUS
		}
	},
	getBookingStatusCli: (
		bookingStatusApi: string | undefined
	): IGetMyBookingsResponseCli[0]["status"] => {
		switch (bookingStatusApi) {
			case AppBookingStatus.APPROVED:
				return AppBookingStatus.APPROVED
			case AppBookingStatus.PENDING:
				return AppBookingStatus.PENDING
			case AppBookingStatus.CANCELED:
				return AppBookingStatus.CANCELED
			case AppBookingStatus.EXPIRED:
				return AppBookingStatus.EXPIRED
			default:
				return undefined
		}
	},

	getBookingCategoriesCli: (
		bookingCategoryApi: string | undefined
	): IGetMyBookingsResponseCli[0]["category"] => {
		switch (bookingCategoryApi) {
			case BookingCategories.EVENTS:
				return BookingCategories.EVENTS
			case BookingCategories.RESTAURANTS:
				return BookingCategories.RESTAURANTS
			case BookingCategories.SPACES:
				return BookingCategories.SPACES
			default:
				return undefined
		}
	},
	clearLoginDataFromLocalStorage: (): void => {
		localStorage.removeItem(AppLocalStorageKeys.TOKEN)
		localStorage.removeItem(AppLocalStorageKeys.REFRESH_TOKEN)
		localStorage.removeItem(AppLocalStorageKeys.TOKEN_EXPIRATION_TIME)
		IndexedDB.deleteDatabase()
	},
	clearPaymentDataFromLocalStorage: (): void => {
		localStorage.removeItem(AppLocalStorageKeys.CHECKOUT)
		localStorage.removeItem(AppLocalStorageKeys.PAYMENT_REF)
		localStorage.removeItem(AppLocalStorageKeys.PAYMENT_GATEWAY)
		localStorage.removeItem(AppLocalStorageKeys.PAY_PENDING_MESSAGE_SHOWN)
	},
	isAuthenticated: (): boolean => {
		return !!localStorage.getItem(AppLocalStorageKeys.TOKEN)
	},
	isLoginRemembered: (): boolean => {
		return localStorage.getItem(AppLocalStorageKeys.REMEMBER_ME) !== "false"
	},
	goToLogin: (): void => {
		if (!window.location.pathname.includes(AppRoutes.login)) {
			window.location.href = `${AppRoutes.login}?lastUrl=${window.location.href}`
		}
	},
	getErrorApiDetail: (error: any): IGetErrorApiDetail => {
		return {
			message: error?.response?.data?.error?.details,
			status: error?.response?.status,
		}
	},
	getUserLocation: (
		success: (position: { lat: number; lng: number }) => void,
		error?: (error: GeolocationPositionError) => void
	) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				success({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				})
			}, error)
		} else {
			if (error)
				error({
					code: 0,
					message: "Geolocation is not supported",
				} as GeolocationPositionError)
		}
	},
	loadGoogleMapsAPI: (onload: () => void) => {
		if (
			typeof window.google === "object" &&
			typeof window.google.maps === "object"
		) {
			onload()
			return
		}

		if (!document.getElementById("google-maps-script")) {
			const script = document.createElement("script")
			script.id = "google-maps-script"
			script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places`
			script.async = true
			script.defer = true
			script.setAttribute("loading", "async")
			script.onload = () => onload()
			document.head.appendChild(script)
		}
	},
	openWebSendEmail: (
		email: string,
		subject: string = "",
		body: string = ""
	) => {
		return `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`
	},
	openWebSendWhatsapp: (
		countryCode: string,
		phoneNumber: string,
		message: string = ""
	) => {
		return `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURIComponent(
			message
		)}`
	},
	getCurrentDomain: () => window.location.origin,
	getUrlParam: (paramName: string): string | null => {
		const urlParams = new URLSearchParams(window.location.search)
		return urlParams.get(paramName)
	},
	capitalizeFirstChar: (str: string) => {
		return str.trim().charAt(0).toUpperCase() + str.slice(1)
	},
	isInDevelopment: () => import.meta.env.MODE === "development",
	getViteEnv: (key: keyof IViteEnv): IViteEnv[keyof IViteEnv] => {
		switch (key) {
			case "VITE_USE_MOCKS":
				return import.meta.env[key] === "true"
			default:
				return import.meta.env[key]
		}
	},
	handleLogin: (data: IPostLoginResponseApi) => {
		const currentTime = Date.now()
		const expiresInMs = data.expires_in * 1000
		const expirationTime = currentTime + expiresInMs

		localStorage.setItem(AppLocalStorageKeys.TOKEN, data.token)

		localStorage.setItem(
			AppLocalStorageKeys.REFRESH_TOKEN,
			data.refreshToken
		)

		localStorage.setItem(
			AppLocalStorageKeys.TOKEN_EXPIRATION_TIME,
			expirationTime.toString()
		)
	},
	inTestEnvironment: () => process?.env?.NODE_ENV === "test",
	checkIfIsInTheFuture: ({ date, zone }: ICheckIfIsInTheFuture) => {
		const currentDayByZone =
			zone === "Local" ? dayjs() : dayjs().tz("America/Montevideo")

		const targetDateByZone =
			zone === "Local"
				? dayjs(date)
				: dayjs.tz(date, "America/Montevideo")

		return targetDateByZone.isAfter(currentDayByZone)
	},
	generateBillingCycle: (args: {
		monthlyPrice: number
		annualPrice: number
		accessFee: number
		lang: "es" | "en"
		corporate: boolean
	}) => {
		const priceDescription = args.corporate
			? args.lang === "en"
				? "monthly per member"
				: "mensual por miembro"
			: args.lang === "en"
				? "monthly"
				: "mensual"

		return [
			{
				id: "monthly",
				name: args.lang === "en" ? "Monthly" : "Mensual",
				price: args.monthlyPrice,
				priceDescription,
				currency: "USD",
				firstPayment: args.monthlyPrice + args.accessFee,
				remainingPayments: args.monthlyPrice,
				total:
					args.monthlyPrice + args.accessFee + 11 * args.monthlyPrice,
			},
			{
				id: "annual",
				name: args.lang === "en" ? "Annual" : "Anual",
				price: args.annualPrice,
				priceDescription,
				currency: "USD",
				annuity: 12 * args.annualPrice,
				accessFee: args.accessFee,
				total: 12 * args.annualPrice + args.accessFee,
			},
		]
	},
}
