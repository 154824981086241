import * as React from "react"
import styles from "./MembershipKindOfForm.module.scss"

// import { AppRoutes } from "@constants/AppRoutes.ts"
// import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { usePlanData } from "@hooks/usePlanData.tsx"
import { useMembershipApplyForm } from "@hooks/useMembershipApplyForm.tsx"

// import PlanDetailItem from "@components/dataEntry/MembershipKindOfForm/PlanDetailItem/PlanDetailItem.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
// import DsPlanInfoCard from "@components/dataDisplay/DsPlanInfoCard/DsPlanInfoCard.tsx"
// import MembershipFormFooter from "@components/layout/MembershipFormFooter/MembershipFormFooter.tsx"

export interface IMembershipKindOfFormFieldType {
	plan?: string
	paymentFrequency?: string
}

interface IProps {
	className?: string
}

const MembershipKindOfForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { planData } = usePlanData()
	// const navigate = useNavigate()

	const initialValues: IMembershipKindOfFormFieldType = React.useMemo(
		() => ({
			planId: planData?.defaultPlan,
			paymentFrequency: planData?.defaultBillingCycle,
		}),
		[planData]
	)

	const { states, onChange } = useMembershipApplyForm(
		undefined,
		initialValues
	)

	// const monthlyBill = planData?.plans.find(
	// 	(plan) => plan.id === states.plan
	// )?.monthlyBill
	//
	// const annualBill = planData?.plans.find(
	// 	(plan) => plan.id === states.plan
	// )?.annualBill

	// const onFinish = async () => {
	// 	await onChange("plan", states.plan)
	// 	await onChange("paymentFrequency", states.paymentFrequency)
	//
	// 	navigate(AppRoutes.membershipFormStep2_personalDetails, {
	// 		replace: true,
	// 	})
	// }

	return (
		<div
			className={`${styles.membership_kind_of_form} ${props.className || ""}`}
		>
			<div className={styles.payment_details_container}>
				<div className={styles.frequency_payment_actions}>
					<label>{t("choose_your_payment_method")}</label>

					<div className={styles.button_container}>
						<DsButton
							className={`${styles.payment_button} ${states.paymentFrequency !== "monthly" && styles.disabled}`}
							onClick={() =>
								onChange("paymentFrequency", "monthly")
							}
						>
							{t("monthly")}
						</DsButton>

						<DsButton
							className={`${styles.payment_button} ${states.paymentFrequency !== "annual" && styles.disabled}`}
							onClick={() =>
								onChange("paymentFrequency", "annual")
							}
						>
							{t("annual")}
						</DsButton>
					</div>
				</div>

				{/*<div className={styles.payment_details}>*/}
				{/*	{states.paymentFrequency === "monthly" && monthlyBill && (*/}
				{/*		<>*/}
				{/*			<PlanDetailItem*/}
				{/*				title={t("payment_1")}*/}
				{/*				subtitle={t("monthly_plus_access_fee")}*/}
				{/*				currency="US$"*/}
				{/*				value={monthlyBill.firstPayment}*/}
				{/*			/>*/}

				{/*			<PlanDetailItem*/}
				{/*				title={t("payment_2_12")}*/}
				{/*				currency="US$"*/}
				{/*				value={monthlyBill.remainingPayments}*/}
				{/*				unit={t("ea")}*/}
				{/*				strongBorder*/}
				{/*			/>*/}

				{/*			<PlanDetailItem*/}
				{/*				title={t("total")}*/}
				{/*				currency="US$"*/}
				{/*				value={monthlyBill.total}*/}
				{/*				strongText*/}
				{/*			/>*/}
				{/*		</>*/}
				{/*	)}*/}

				{/*	{states.paymentFrequency === "annual" && annualBill && (*/}
				{/*		<>*/}
				{/*			<PlanDetailItem*/}
				{/*				title={t("annuity")}*/}
				{/*				currency="US$"*/}
				{/*				value={annualBill.annuity}*/}
				{/*			/>*/}

				{/*			<PlanDetailItem*/}
				{/*				title={t("access_fee")}*/}
				{/*				currency="US$"*/}
				{/*				value={annualBill.accessFee}*/}
				{/*				strongBorder*/}
				{/*			/>*/}

				{/*			<PlanDetailItem*/}
				{/*				title={t("total")}*/}
				{/*				currency="US$"*/}
				{/*				value={annualBill.total}*/}
				{/*				strongText*/}
				{/*			/>*/}
				{/*		</>*/}
				{/*	)}*/}
				{/*</div>*/}
			</div>

			{/*<div className={styles.plan_cards}>*/}
			{/*	{planData?.plans.map(({ id, name, type, info }) => {*/}
			{/*		return (*/}
			{/*			<DsPlanInfoCard*/}
			{/*				key={id}*/}
			{/*				name={name}*/}
			{/*				type={type}*/}
			{/*				info={info}*/}
			{/*				selected={id === states.plan}*/}
			{/*				onCardClick={() => onChange("plan", id)}*/}
			{/*			/>*/}
			{/*		)*/}
			{/*	})}*/}

			{/*	<MembershipFormFooter*/}
			{/*		onBackClick={() =>*/}
			{/*			navigate(AppRoutes.membershipFormStep1_address)*/}
			{/*		}*/}
			{/*		onNextClick={onFinish}*/}
			{/*	/>*/}
			{/*</div>*/}
		</div>
	)
}

export default React.memo(MembershipKindOfForm)
