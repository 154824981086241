import api from "@others/api.ts"

import { IApiResponse } from "@interfaces/generic/IApiResponse.ts"
import { IPostLoginResponseApi } from "@interfaces/IPostLoginResponseApi.ts"
import { Endpoints } from "@constants/Endpoints.tsx"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IPostLoginBodyApi } from "@interfaces/IPostLoginBodyApi.ts"
import { AppConsoleMessages } from "@constants/AppConsoleMessages.ts"
import { IPostCheckEmailBodyApi } from "@interfaces/IPostCheckEmailBodyApi.ts"
import { IPostCheckEmailResponseApi } from "@interfaces/IPostCheckEmailResponseApi.ts"
import { IPostSignupBodyApi } from "@interfaces/IPostSignupBodyApi.ts"
import { IPostSignupResponseApi } from "@interfaces/IPostSignupResponseApi.ts"
import { PostLoginAdapter } from "@adapters/PostLoginAdapter.ts"
import { IPostLoginResponseCli } from "@interfaces/IPostLoginResponseCli.ts"

const handleLogin = (data: IPostLoginResponseApi) => {
	const currentTime = Date.now()
	const expiresInMs = data.expires_in * 1000
	const expirationTime = currentTime + expiresInMs

	localStorage.setItem(AppLocalStorageKeys.TOKEN, data.token)

	localStorage.setItem(AppLocalStorageKeys.REFRESH_TOKEN, data.refreshToken)

	localStorage.setItem(
		AppLocalStorageKeys.TOKEN_EXPIRATION_TIME,
		expirationTime.toString()
	)
}

const login = async ({
	email,
	password,
}: IPostLoginBodyApi): Promise<IPostLoginResponseCli> => {
	return api
		.post<IApiResponse<IPostLoginResponseApi>>(Endpoints.login, {
			email,
			password,
		})
		.then((response) => {
			const { data } = response.data
			handleLogin(data)
			return PostLoginAdapter(response.data)
		})
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const refreshToken = async (): Promise<void> => {
	try {
		const refreshToken = localStorage.getItem(
			AppLocalStorageKeys.REFRESH_TOKEN
		)

		if (!refreshToken) {
			throw new Error(AppConsoleMessages.error.no_refresh_token)
		}

		const response = await api.post<IApiResponse<IPostLoginResponseApi>>(
			Endpoints.refreshToken,
			{
				refreshToken,
			}
		)

		handleLogin(response.data.data)
	} catch {
		console.error(AppConsoleMessages.error.refresh_token_failed)
		AppFunctions.clearLoginDataFromLocalStorage()
		AppFunctions.goToLogin()
	}
}

const checkEmail = async ({
	email,
}: IPostCheckEmailBodyApi): Promise<IPostCheckEmailResponseApi> => {
	return api
		.post<IApiResponse<IPostCheckEmailResponseApi>>(Endpoints.checkEmail, {
			email,
		})
		.then((response) => {
			const { data } = response.data
			return data
		})
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

const signup = async (
	jsonBody: IPostSignupBodyApi
): Promise<IPostSignupResponseApi> => {
	return api
		.post<IApiResponse<IPostSignupResponseApi>>(Endpoints.signUp, jsonBody)
		.then((response) => {
			const { data } = response.data
			return data
		})
		.catch((error) => {
			throw AppFunctions.getErrorApiDetail(error)
		})
}

export default { login, refreshToken, checkEmail, signup }
