import * as React from "react"
import styles from "./DropDownHeader.module.scss"

import { KeyOutlined } from "@ant-design/icons"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import DvUserAvatar from "@components/dataDisplay/DvUserAvatar/DvUserAvatar.tsx"
import DvConfirmationModal from "@components/feedback/DvConfirmationModal/DvConfirmationModal.tsx"
import UserQrModalContent from "@components/navigation/DsProfileDropDown/DropDownHeader/UserQrModalContent/UserQrModalContent.tsx"

interface IProps {
	className?: string
}

const DropDownHeader: React.FC<IProps> = (props) => {
	const { loggedUser } = useAuthCtx()

	const [qrModal, setQrModal] = React.useState({
		open: false,
		data: {},
	})

	return (
		<>
			<div
				className={`${styles.drop_down_header_container} ${props.className || ""}`}
			>
				<DvUserAvatar className={styles.avatar} />

				<div className={styles.name_container}>
					{(loggedUser?.name || loggedUser?.lastName) && (
						<h1>
							{loggedUser.name} {loggedUser.lastName}
						</h1>
					)}

					{(loggedUser?.plan || loggedUser?.corporate) && (
						<div className={styles.membership}>
							<h3>{loggedUser.plan}</h3>
							<p>{loggedUser.corporate}</p>
						</div>
					)}
				</div>

				<DsButton
					type="secondary"
					icon={<KeyOutlined />}
					textKey="access_code"
					onClick={() => {
						setQrModal({ open: true, data: {} })
					}}
				/>
			</div>

			<DvConfirmationModal
				open={qrModal.open}
				width={264}
				hideFooter
				onCancel={() => setQrModal({ open: false, data: {} })}
			>
				<UserQrModalContent />
			</DvConfirmationModal>
		</>
	)
}

export default React.memo(DropDownHeader)
