import * as React from "react"

import { IGetUserLoggedResponseCli } from "@interfaces/IGetUserLoggedResponseCli.ts"

import useAuthCtx from "@contexts/hooks/useAuthCtx.tsx"
import userService from "@services/userService/userService.ts"

interface IOutput {
	loggedUser?: IGetUserLoggedResponseCli
	fetchLoggedUser: () => Promise<void>
	setPaidMembership: () => void
	setInProgressPaymentMembership: () => void
}

export const useLoggedUserData = (): IOutput => {
	const { loggedUser, setLoggedUser } = useAuthCtx()

	const fetchLoggedUser = () =>
		new Promise<void>((resolve, reject) => {
			userService
				.getUserLogged()
				.then((user) => {
					setLoggedUser(user)
					resolve()
				})
				.catch(({ message }) => {
					console.error(
						message || "Something went wrong fetching the user data"
					)
					reject()
				})
		})

	const setInProgressPaymentMembership = React.useCallback(() => {
		setLoggedUser((prev) => ({
			...prev,
			membershipStatus: "PAYMENT_IN_PROGRESS",
		}))
	}, [setLoggedUser])

	const setPaidMembership = React.useCallback(() => {
		setLoggedUser((prev) => ({
			...prev,
			membershipStatus: "PAID",
		}))
	}, [setLoggedUser])

	return {
		loggedUser,
		fetchLoggedUser,
		setPaidMembership,
		setInProgressPaymentMembership,
	}
}
