import * as React from "react"
import { Navigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useLoggedUserData } from "@hooks/useLoggedUserData.ts"
import { AppMembershipStatus } from "@constants/AppMembershipStatus.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"

export interface IExternalMembershipApplies {
	plan: string
	paymentFrequency: string
}

const ExternalMembershipAppliesFlow: React.FC = () => {
	const { loggedUser, fetchLoggedUser } = useLoggedUserData()

	const plan = AppFunctions.getUrlParam("plan")
	const paymentFrequency = AppFunctions.getUrlParam("paymentFrequency")

	// externalMembershipApplies is defined only if plan and paymentFrequency are defined
	const externalMembershipApplies: IExternalMembershipApplies | undefined =
		plan && paymentFrequency
			? {
					plan,
					paymentFrequency,
				}
			: undefined

	// If some error in the parameters, redirect to home
	if (!externalMembershipApplies) {
		return <Navigate to={AppRoutes.home} replace />
	} else {
		localStorage.setItem(
			AppLocalStorageKeys.EXTERNAL_MEMBERSHIP_APPLIES_FLOW_IN_PROGRESS,
			"true"
		)

		if (AppFunctions.isAuthenticated()) {
			if (loggedUser) {
				switch (loggedUser.membershipStatus) {
					case AppMembershipStatus.NO_MEMBERSHIP:
						return (
							<Navigate
								to={AppRoutes.membershipStartApplying}
								state={{ externalMembershipApplies }}
								replace
							/>
						)
					default:
						return (
							<Navigate
								to={AppRoutes.userProfile}
								state={{ externalMembershipApplies }}
								replace
							/>
						)
				}
			} else {
				fetchLoggedUser()
				return null
			}
		} else {
			return (
				<Navigate
					to={AppRoutes.validateAccount}
					state={{ externalMembershipApplies }}
					replace
				/>
			)
		}
	}
}

export default React.memo(ExternalMembershipAppliesFlow)
