import * as React from "react"
import * as Antd from "antd"
import styles from "./Switches.module.scss"

import { usePlanData } from "@hooks/usePlanData.tsx"

import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface ISwitchesProps {
	className?: string
	defaultSubscription?: string
	defaultBillingCycle?: string
	onSubscriptionChange?: (value: string) => void
	onBillingCycleChange?: (value: string) => void
}

const Switches: React.FC<ISwitchesProps> = (props) => {
	const { planData } = usePlanData()
	const { isDesktop } = useResponsiveToolCtx()

	const desktopPlanButtonStyles: React.CSSProperties | undefined = isDesktop
		? { minWidth: "150px", textAlign: "center" }
		: undefined

	const desktopPaymentButtonStyles: React.CSSProperties | undefined =
		isDesktop ? { minWidth: "106px", textAlign: "center" } : undefined

	return (
		<div className={`${styles.switches} ${props.className || ""}`}>
			<Antd.Radio.Group
				className={styles.switch}
				defaultValue={props.defaultSubscription}
				optionType="button"
				buttonStyle="solid"
				onChange={(e) => props?.onSubscriptionChange?.(e.target.value)}
				options={planData?.plans?.[0]?.subscriptions?.map(
					({ id, name }) => ({
						value: id,
						label: name,
						style: desktopPlanButtonStyles,
					})
				)}
			/>

			<Antd.Radio.Group
				className={styles.switch}
				defaultValue={props?.defaultBillingCycle}
				optionType="button"
				buttonStyle="outline"
				onChange={(e) => props?.onBillingCycleChange?.(e.target.value)}
				options={planData?.plans?.[0]?.subscriptions?.[0]?.billingCycle?.map(
					({ id, name }) => ({
						value: id,
						label: name,
						style: desktopPaymentButtonStyles,
					})
				)}
			/>
		</div>
	)
}

export default React.memo(Switches)
